import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
// Add the Performance Monitoring library
import "firebase/performance";
import "firebase/analytics";
import CheckLogin from "./CheckLogin";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA74EXFWnvgMiLPTga19iVxv1ESv3Skboo",
    authDomain: "reach-dash-2021.firebaseapp.com",
    projectId: "reach-dash-2021",
    storageBucket: "reach-dash-2021.appspot.com",
    messagingSenderId: "581254149715",
    appId: "1:581254149715:web:0e43d120e9cf40f8b9f8a6",
    measurementId: "G-JHVYCYGB7P"
  };

firebase.initializeApp(firebaseConfig);
firebase.analytics();
// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();
ReactDOM.render(<CheckLogin />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
