import React, { Component, useState } from "react";
import { Grommet, Drop, Select, Button } from "grommet";
import styled from "styled-components";
import "./App1.css";
import firebase from "firebase/app";
import "firebase/functions";
import { Chart } from "react-google-charts";
import { saveAs } from "file-saver";
import {usePopper} from 'react-popper';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import "firebase/functions";
const fs = require("browserify-fs");
const { Parser } = require("json2csv");
class App extends Component {
    constructor(props) {
        super(props);

        this.targetRef = React.createRef();
        this.state = {
            query: "",
            result: null,

            isClear: true,
            sheetData: [],
            sheetDataTitle: [],
            CardData: [],
            CardTitle: [],
            TableData: [],
            TableTitle: [],
            tabs: "0",
            loader: false,
            loaderText: "Fetching filters...",
            FinalVals: [],
            chartQueries: [],
            chartTitles: [],
            currentVal: [],
            currentTitle: [],
            currentType: null,
            chartSize: [],
            currentSize: [],
            popUp: [],
            popUpStatus: "false",
            chartWrapper: null,
            haxis: [],
            vaxis: [],
            currentYaxis: null,
            currentHaxis: null,
            colors: null,
            currentColor: null,
            cardbreaker: [],
            graphbreaker: [],
            currentBreaker: [],
            finalUnits: [],
            currentUnits: [],
            index: null,
            reportTitles: null,
            projectName: [],
            projectInfo: [],
            preload: false,
            filterResults: [],
            filterLen: [],
            selectProj: '',
            projectList: [],
            finalSort: [],
            currentSort: [],
            finalTooltips : [],
            currentTooltip: []
        };

        this.reportExist = true;
        this.userQuery = {};
        this.userProject = {};
        this.projectInfo = [];
        this.CardData1 = [];
        this.CardTitle1 = [];
        this.sheetData1 = [];
        this.sheetDataTitle1 = [];
        this.CardData2 = [];
        this.CardTitle2 = [];
        this.sheetData2 = [];
        this.sheetDataTitle2 = [];
        this.CardData = [];
        this.CardTitle = [];
        this.sheetData = [];
        this.sheetDataTitle = [];

        this.handleChange = this.handleChange.bind(this);
        this.print = this.print.bind(this);
        this.onChartClick = this.onChartClick.bind(this);
        this.logout = this.logout.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.OutsideClick = this.OutsideClick.bind(this);
        this.EscapePop = this.EscapePop.bind(this);
        this.download = this.download.bind(this);
        this.rawresultVals = [];
        this.rawresultReports = [];
        this.reportsDownload = this.reportsDownload.bind(this);
        this.queryOnChange = this.queryOnChange.bind(this);
        this.queryFilter = this.queryFilter.bind(this);
        this.clearQuery = this.clearQuery.bind(this);
        this.handleProjChange = this.handleProjChange.bind(this);
        this.createDash  = this.createDash.bind(this);
        this.MyStyledButton = styled(Button)`
            display: inline-block;

            font-weight: 500;
            width: 100px;
            height: 45px;
            margin-right: 20px;
        `;

        this.customTheme = {
            select: {
                options: {
                    container: {
                        size: "xlarge",
                    },
                    text: {
                        size: "10px",
                    },
                },
            },
            global: {
                drop: {
                    zIndex: "9999",
                },
                font: {
                    family: "'Fira Sans', sans-serif",
                    size: "10px",
                },
                colors: {
                    // border: "rgb(125, 76, 219)"
                },
                input: {},
            },
            button: {
                border: {
                    radius: "4px",
                    color: "#8e4a21",
                },
                primary: {
                    color: "#8e4a21",
                },
                extend: `     
        font-size: 12px;
        font-weight: 500;
        `,
            },
        };
    }
createDash = (userData, selectProj) => {
     this.setState({ loader: true });
        // var unique = a =>
        //   a.filter(function(itm, i, a) {
        //     return i === a.indexOf(itm);
        //   });

        var unique = (array) => {
            let distinct = [...new Set(array)];
            return distinct;
        };

        var query = firebase.functions().httpsCallable("sqlquery");
        var firestore = firebase.functions().httpsCallable("firestore");

        // var promise1 = new Promise((resolve, reject) => {
        //   query({ text: this.queryText }).then(result => {
        //     resolve(result)
        //   });
        // });
     
     
        firestore({text: selectProj}).then((result) => {
            console.log(result);
            var responseData = result["data"];
            // this.queryText = responseData[0]["filterQuery"];
            // var userQuery = responseData[0]["queries"];
            console.log(userData);
            var projIndex = `filterQuery${userData['path'].indexOf(selectProj) + 1}`
            this.queryText = userData[projIndex];
            var userQuery = userData["queries"][selectProj];
            console.log(this.queryText);
            this.userProject = responseData["Queries"];
          
            var filters = responseData["Filters"];
            console.log(this.userProject);
            var filterVal = [],
                filterTxt = [];
            console.log(filters);

            // for (var i = 0; i <= arrayFilter.length - 1; i++) {
            //     if (arrayFilter[i] !== "") {
            //         if (and === true) {
            //             query = query.concat(`AND ${arrayWhere[i]} = '${arrayFilter[i]}' `);
            //         }
            //         if (and === false) {
            //             query = query.concat(` ${arrayWhere[i]} = '${arrayFilter[i]}' `);
            //             and = true;
            //         }
            //     }
            // }

            for (let [key, values] of Object.entries(filters)) {
                for (let [keyq, valuesq] of Object.entries(values)) {
                    filterVal.push(valuesq);
                    filterTxt.push(keyq);
                }
            }
            console.log(filterTxt, filterVal);
            this.filterVal = filterVal;
            this.filterTxt = filterTxt;
            if (this.userProject.hasOwnProperty("reports")) {
                if (userQuery !== null) {
                    if (!userQuery.hasOwnProperty("reports")) {
                        this.reportExist = false;
                    }
                }
            } else {
                this.reportExist = false;
            }

            var projectInfo = [
                responseData["App Bar Name"],
                responseData["Description"],
                responseData["Start Date"],
                responseData["End Date"],
                responseData["Partners"],
                responseData["Logo"],
                responseData["App Bar Color"],
                responseData["Button Color"],
                responseData["Logo Title"],
            ];
        
            console.log(userQuery);
            if (userQuery !== null) {
                for (let [key, values] of Object.entries(userQuery)) {
                    console.log(key, values);
                    this.userQuery[key] = {};
                    this.userQuery[key]["projectName"] = {};

                    this.userQuery[key]["cardbreaker"] = {};
                    this.userQuery[key]["graphbreaker"] = {};
                    this.userQuery[key]["projectName"] = this.userProject[key]["projectName"];
                    this.userQuery[key]["cardbreaker"] = this.userProject[key]["cardbreaker"];
                    this.userQuery[key]["graphbreaker"] = this.userProject[key]["graphbreaker"];

                    this.userQuery[key]["queries"] = {};
                    values.forEach((reVal, reInd) => {
                        console.log(reInd);
                        var hmmm;
                        this.userQuery[key]["queries"][reInd] = {};
                        this.userQuery[key]["queries"][reInd] = this.userProject[key]["queries"][reVal];
                    });
                }
            } else {
                this.userQuery = this.userProject;
            }

            console.log(this.userQuery);
            query({ text: this.queryText }).then((result) => {
                console.log(result);

                var resultData = result["data"];
                var filterResults = [];
                for (var i = 0; i <= filterVal.length - 1; i++) {
                    filterResults.push([]);
                }
                for (var i = 0; i <= resultData.length - 1; i++) {
                    for (var u = 0; u <= filterVal.length - 1; u++) {
                        filterResults[u].push(resultData[i][filterVal[u]]);
                    }
                }
                console.log(filterResults);

                filterResults = filterResults.map((val, index) => {
                    return unique(val);
                });
                var isClear = true;
                var filterLen = filterResults.map((val, index) => {
                    var killinIt = val.length > 1 ? "" : val[0];
                    if (killinIt === "") {
                        isClear = false;
                    }
                    return killinIt;
                });
                this.filterResults = filterResults;

                this.filterLen = filterLen;
                this.isClear = isClear;
                console.log(this.filterLen, this.isClear, this.filterResults);

                this.setState({
                    filterResults,
                    filterLen,

                    loader: false,
                    projectInfo,
                    preload: true,

                    isClear,
                    selectProj:selectProj,
                    projectList:userData["path"]
                });
            });
        });
}
    componentDidMount = () => {

        var createPool = firebase.functions().httpsCallable("createPool");
        createPool().then((result) => {
            console.log(result);
        });

        this.createDash(this.props.userData, this.props.selectProj)
   
    };

    handleMoreClick = () => {
        this.setState((prevState) => ({
            showDotMenu: !prevState.showDotMenu,
        }));
    };
    logout = () => {
        var cache = this;
        firebase
            .auth()
            .signOut()
            .then(function () {
                cache.setState({
                    signedIn: false,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    onChartClick = (index) => {
        document.addEventListener("keydown", this.EscapePop, false);

        var popUpArray = [
            this.state.currentTitle[index],
            this.state.currentType[index],
            this.state.currentVal[index],
            this.state.currentHaxis[index],
            this.state.currentYaxis[index],
            this.state.currentColor[index],
        ];
        this.setState({
            popUp: popUpArray,
            popUpStatus: "true",
            index,
        });
    };
    closePopup = () => {
        document.removeEventListener("keydown", this.escFunction, false);
        this.setState({
            popUp: null,
            popUpStatus: "false",
        });
    };

    queryFilter = () => {
        var unique = (array) => {
            let distinct = [...new Set(array)];
            return distinct;
        };

        var arrayFilter = this.state.filterLen.splice(0);
        console.log(arrayFilter);

        var query = this.queryText.toLowerCase().includes("where") ? "" : "WHERE ";
        var and = this.queryText.toLowerCase().includes("where");
        var arrayWhere = this.filterVal;
        // console.log(query, and, arrayWhere);
        for (var i = 0; i <= arrayFilter.length - 1; i++) {
            if (arrayFilter[i] !== "") {
                if (and === true) {
                    query = query.concat(`AND ${arrayWhere[i]} = '${arrayFilter[i]}' `);
                }
                if (and === false) {
                    query = query.concat(` ${arrayWhere[i]} = '${arrayFilter[i]}' `);
                    and = true;
                }
            }
        }
        var query1 = this.queryText + " " + query;
        console.log(query1);
        var filtering = firebase.functions().httpsCallable("query");
        var filteringSql = firebase.functions().httpsCallable("sqlquery");

        // filteringSql({ text: "asdsadasda" }).then((result) => {
        //     console.log(result);
        // });

        filteringSql({ text: query1 }).then((result) => {
            console.log(result);

            var resultData = result["data"];
            var filterResults = [];
            for (var i = 0; i <= this.filterVal.length - 1; i++) {
                filterResults.push([]);
            }
            for (var i = 0; i <= resultData.length - 1; i++) {
                for (var u = 0; u <= this.filterVal.length - 1; u++) {
                    filterResults[u].push(resultData[i][this.filterVal[u]]);
                }
            }
            console.log(filterResults);

            filterResults = filterResults.map((val, index) => {
                return unique(val);
            });
            var isClear = this.state.isClear;
            var filterLen1 = filterResults.map((val, index) => {
                if (arrayFilter[index] === "") {
                    var killinIt = val.length > 1 ? "" : val[0];
                    if (killinIt === "") {
                        isClear = false;
                    }
                    return killinIt;
                } else {
                    return arrayFilter[index];
                }
            });

            console.log(filterLen1, isClear);

            this.setState({
                filterResults,
                filterLen: filterLen1,
                isClear,
                loader: false,
            });
        });

        // console.log(query1);
    };

    queryOnChange = (e, i) => {
        // console.log(e, i, this.filterLen);
        var asd = this.state.filterLen.slice(0);

        asd[i] = e;
        // console.log(filterLen11);
        // console.log(this.filterLen);
        this.setState(
            {
                filterLen: asd,
                isClear: false,
                loader: true,
            },
            function () {
                this.queryFilter();
            }
        );
    };

    clearQuery = () => {
        console.log(this.isClear, this.filterLen, this.filterResults);
        this.setState({
            filterResults: this.filterResults,
            filterLen: this.filterLen,
            isClear: this.isClear,
        });
    };
    print = () => {
        if (this.state.chartWrapper === null) {
            console.error("ChartWrapper not ready yet");
        }
        const image = this.state.chartWrapper.getChart().getImageURI();
        console.log(image);
        saveAs(image, this.state.popUp[0]);
    };
    download = () => {
        var myData = this.rawresultVals[parseInt(this.state.tabs)][this.state.index];
        var title = this.state.popUp[0];
        console.log(myData);
        try {
            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(myData);
            console.log(csv);
            const blob = new Blob([csv], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            saveAs(blob, `${title}.csv`);

            // const blob = new Blob([csv], {
            //   type: "application/vnd.ms-excel;charset=utf-8"
            // });
            // saveAs(blob, `${title}.xlsx`);
        } catch (err) {
            console.error(err);
        }
    };
    submitQuery = () => {
        if (!this.state.isClear) {
            this.setState({ loader: true, loaderText: "Fetching data..." });
            var query = ``;

            var arrayFilter = this.state.filterLen.slice(0);
                     
            // var query = this.queryText.toLowerCase().includes("where") ? "" : "WHERE ";
            // var and = this.queryText.toLowerCase().includes("where");
            // var arrayWhere = this.filterVal;
            // for (var i = 0; i <= arrayFilter.length - 1; i++) {
            //     if (arrayFilter[i] !== "") {
            //         if (and === true) {
            //             query = query.concat(`AND ${arrayWhere[i]} = '${arrayFilter[i]}' `);
            //         }
            //         if (and === false) {
            //             query = query.concat(` ${arrayWhere[i]} = '${arrayFilter[i]}' `);
            //             and = true;
            //         }
            //     }
            // }
            var query1;
            var and = false;
            console.log(this.filterVal, arrayFilter);
            var arrayWhere = this.filterVal;

            for (var i = 0; i <= arrayFilter.length - 1; i++) {
                if (arrayFilter[i] !== "") {
                    if (and === true) {
                        query = query.concat(`AND ${arrayWhere[i]} = '${arrayFilter[i]}' `);
                    }
                    if (and === false) {
                        query = `WHERE `;
                        query = query.concat(` ${arrayWhere[i]} = '${arrayFilter[i]}' `);
                        and = true;
                    }
                }
            }

            query1 = " " + query;
           
            var sortQuery = (query) => {
                if (query.includes("UNION")) {
                    var unionQ = query;
                    var count = (unionQ.match(/UNION/g) || []).length;
                    // console.log(count);
                    var indexes = [];
                    var index = 0;
                    for (var i = 0; i <= count; i++) {
                        var replace, groupIndex, format;
                        if (i !== count) {
                            index = unionQ.indexOf("UNION");
                            // console.log(index);
                            var befUnion = unionQ.slice(0, index);
                            // console.log(befUnion);

                            index = index + 9;
                            unionQ = unionQ.slice(index);
                            // console.log(unionQ);

                            if (befUnion.includes("where")) {
                                replace = query1.replace("WHERE", "AND");
                                groupIndex = befUnion.indexOf("group");
                                // console.log(groupIndex);
                                format = [befUnion.slice(0, groupIndex), replace, befUnion.slice(groupIndex)].join("");

                                indexes[i] = format;
                            } else {
                                replace = query1;
                                groupIndex = befUnion.indexOf("group");
                                format = [befUnion.slice(0, groupIndex), replace, befUnion.slice(groupIndex)].join("");

                                indexes[i] = format;
                            }
                        } else {
                            befUnion = unionQ;
                            if (befUnion.includes("where")) {
                                replace = query1.replace("WHERE", "AND");
                                groupIndex = befUnion.indexOf("group");
                                // console.log(groupIndex);
                                format = [befUnion.slice(0, groupIndex), replace, befUnion.slice(groupIndex)].join("");

                                indexes[i] = format;
                            } else {
                                replace = query1;
                                groupIndex = befUnion.indexOf("group");
                                format = [befUnion.slice(0, groupIndex), replace, befUnion.slice(groupIndex)].join("");

                                indexes[i] = format;
                            }
                        }
                    }
                    // console.log(indexes);

                    var finalFormat = "";
                    // console.log(indexes);
                    var querLen = indexes.length - 1;
                    indexes.map((quer, i) => {
                        if (querLen !== i) {
                            finalFormat = finalFormat + " " + quer + " UNION ALL";
                        } else {
                            finalFormat = finalFormat + quer;
                        }
                    });
                    // console.log(finalFormat);
                    return finalFormat;
                }

                if (query.includes("JOIN")) {
                    var joinpos = query.indexOf("JOIN");
                    var beforejoin = query.slice(0, joinpos);
                    var afterjoin = query.slice(joinpos);
                          console.log(joinpos,beforejoin,afterjoin)
                    if (beforejoin.includes("where")) {
                        var whereClause = query1.replace("WHERE", "AND");
                        var createQuery = beforejoin;
                        if (afterjoin.includes("group")) {
                            var position = beforejoin.indexOf("group");
                            var sliced1 = [beforejoin.slice(0, position), whereClause, beforejoin.slice(position)].join("");
                        } else {
                            var position = beforejoin.indexOf("`");
                            var sliced1 = [beforejoin.slice(0, position), whereClause, beforejoin.slice(position)].join("");
                        }
                    } else {
                        var createQuery = beforejoin;
                         
                        if (beforejoin.includes("group")) {
                            var position = beforejoin.indexOf("group");
                            var sliced1 = [beforejoin.slice(0, position), query1, beforejoin.slice(position)].join("");
                        } else {
                            console.log(createQuery);
                            var pos1 = createQuery.indexOf("`");
                            var pos2 = createQuery.indexOf("`", pos1 + 1);

                            var sliced1 = [createQuery.slice(0, pos2 + 1), query1, createQuery.slice(pos2 + 1)].join("");
                        }
                    }
                    if (afterjoin.includes("where")) {
                        var whereClause = query1.replace("WHERE", "AND");
                        var createQuery = afterjoin;
                        var position = afterjoin.indexOf("group");
                        var sliced2 = [afterjoin.slice(0, position), whereClause, afterjoin.slice(position)].join("");
                    } else {
                        var createQuery = afterjoin;
                        var position = afterjoin.indexOf("group");
                        var sliced2 = [afterjoin.slice(0, position), query1, afterjoin.slice(position)].join("");
                    }

                    // var whereClause = query1.replace("WHERE", "AND");
                    // var createQuery = afterjoin;
                    // var position = afterjoin.indexOf("group");
                    // var sliced2 = [afterjoin.slice(0, position), whereClause, afterjoin.slice(position)].join("");

                    return `${sliced1} ${sliced2}`;
                }

                if (query.includes("where")) {
                    var createQuery = query;

                    var whereClause = query1.replace("WHERE", "AND");
                    if (createQuery.includes("group")) {
                        var position = query.indexOf("group");
                        var sliced = [createQuery.slice(0, position), whereClause, createQuery.slice(position)].join("");

                        return `${sliced}`;
                    } else {
                        return `${query}${whereClause}`;
                    }
                } else {
                    var createQuery1 = query;
                    if (createQuery1.includes("group")) {
                        var createQuery = query;
                        var position = query.indexOf("group");
                        var sliced = [createQuery1.slice(0, position), query1, createQuery1.slice(position)].join("");

                        return `${sliced}`;
                    } else {
                        return `${query}${query1}`;
                    }
                }
            };

            var allQueries = [];
            var chartQueries = [];
            var chartTitles = [];
            var sortType = [];
            var chartSize = [];
            var haxis = [];
            var vaxis = [];
            var colors = [];
            var cardbreaker = [];
            var graphbreaker = [];
            var projectName = [];
            var finalUnits = [];
            var dashboardLen;
            var finalTarget = [];

            var reportTitles = [];
            var finalSort = [];
            var finalTooltips = [];
            Object.entries(this.userQuery).forEach(([key, value]) => {
                var title = [];
                var size = [];
                var inhaxis = [];
                var invaxis = [];
                var sheetColors = [];
                var units = [];
                var sort = [];
                var tooltip = [];
                cardbreaker.push(value["cardbreaker"]);
                graphbreaker.push(value["graphbreaker"]);
                projectName.push(value["projectName"]);
                Object.entries(value["queries"]).forEach(([key1, value]) => {
                    // console.log(value["query"]);
                    var newQuery = sortQuery(value["query"]);
                    allQueries.push(newQuery);
                    chartQueries.push(value["type"]);
                    title.push(value["title"]);
                    sortType.push(value["sort"]);
                    sort.push(value["sort"]);
                    size.push(value["size"]);
                    inhaxis.push(value["haxis"]);
                    invaxis.push(value["yaxis"]);
                    sheetColors.push(value["colors"]);
                    units.push(value["units"]);
                    tooltip.push(value['tooltip'])
                   
                });
                finalTooltips.push(tooltip)
                chartTitles.push(title);
                chartSize.push(size);
                haxis.push(inhaxis);
                vaxis.push(invaxis);
                colors.push(sheetColors);
                finalUnits.push(units);
                finalSort.push(sort);
            });
                  console.log(finalSort)
            dashboardLen = chartSize.length - 1;

            console.log(allQueries);

            this.sort = (result) => {
                var count = 0;
                chartTitles.map((arr, i) => {
                    var inArr = [];

                    var outcount = 0;
                    arr.map((arr1, j) => {
                        inArr.push(result[`${count + outcount}`]);
                        // inArrTyp.push(chartQueries[`${count + outcount}`]);
                        count++;
                    });
                    outcount++;

                    this.rawresultVals.push(inArr);
                });

                this.rawresultReports = this.rawresultVals.splice(dashboardLen);
                if (this.reportExist == true) {
                    reportTitles = chartTitles.splice(dashboardLen);
                    reportTitles = reportTitles[0];
                } else {
                    reportTitles = null;
                }

                this.rawresultReports = this.rawresultReports[0];

                console.log(this.rawresultVals);
                console.log(this.rawresultReports);
            };
            function transpose(a) {
                // Calculate the width and height of the Array
                var w = a.length || 0;
                var h = a[0] instanceof Array ? a[0].length : 0;

                // In case it is a zero matrix, no transpose routine needed.
                if (h === 0 || w === 0) {
                    return [];
                }

                /**
                 * @var {Number} i Counter
                 * @var {Number} j Counter
                 * @var {Array} t Transposed data is stored in this array.
                 */
                var i,
                    j,
                    t = [];

                // Loop through every item in the outer array (height)
                for (i = 0; i < h; i++) {
                    // Insert a new row (array)
                    t[i] = [];

                    // Loop through every item per item in outer array (width)
                    for (j = 0; j < w; j++) {
                        // Save transposed data.
                        t[i][j] = a[j][i];
                    }
                }

                return t;
            }
            var RawVals = [];
            var FinalVals = [];
            var FinalTypes = [];
            var tempColQuery2 = [];
            // var queryArray = firebase.functions().httpsCallable("queryArray");
            var sqlqueryArray = firebase.functions().httpsCallable("sqlqueryArray");
            // sqlqueryArray({ text: allQueries }).then((result) => {
            //     console.log(result);
            // });
            sqlqueryArray({ text: allQueries })
                .then((result) => {
                    console.log(result);

                    var Rawresult = result["data"];
                    this.sort(Rawresult);
                    Rawresult.map((x, index) => {
                        // console.log(x);
                        var tempColQuery1 = [];
                        var tempHeader = [];

                        if (chartQueries[index] === "Card") {
                            x.map((y, j) => {
                                for (let [key, value] of Object.entries(y)) {
                                    // console.log(`${key}: ${value}`);
                                    tempColQuery1.push(value);
                                }
                            });
                        } else if (chartQueries[index] === "ColumnChart" || chartQueries[index] === "PieChart" || chartQueries[index] === "LineChart") {
                            if (sortType[index] === "Type1") {
                                x.map((y, j) => {
                                    var tempColQuery = [];
                                    var retempHeader = [];
                                    if (j === 0) {
                                        retempHeader = Object.keys(y).reverse();
                                        retempHeader = retempHeader.map((head, i) => {
                                            return head.replace(/_/g, " ");
                                        });
                                        var tempLen = retempHeader.length;

                                        for (var x = 0; x <= tempLen - 1; x++) {
                                            if (x === 0) {
                                                tempHeader.push(retempHeader[x]);
                                            } else {
                                                // else if(x === tempLen){
                                                tempHeader.push(retempHeader[x]);
                                                tempHeader.push({
                                                    type: "string",
                                                    role: "annotation",
                                                });
                                            }
                                        }
                                    }

                                    for (let [key, value] of Object.entries(y)) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }
                                    tempColQuery.reverse();
                                    // console.log(tempColQuery);
                                    var stringing = [];
                                    tempColQuery.map((arrr, k) => {
                                        if (k === 0) {
                                            if (arrr === null) {
                                                arrr = "Null";
                                            }
                                            stringing.push(arrr.toString());
                                        } else {
                                            if (arrr === null) {
                                                arrr = "Null";
                                            }

                                            stringing.push(arrr);
                                            if (arrr === 0) {
                                                arrr = "";
                                            }
                                            stringing.push(arrr.toString());
                                        }
                                    });
                                    tempColQuery1.push(stringing);
                                });
                            } else if (sortType[index] === "Type5") {
                                var retempHeader = [];
                                var tempColQueryagain = [];

                                // console.log(x);
                                x.map((y, j) => {
                                    var tempColQuery = [];

                                    if (j === 0) {
                                        retempHeader = Object.keys(y).reverse();
                                        retempHeader = retempHeader.map((head, i) => {
                                            return head.replace(/_/g, " ");
                                        });
                                        tempColQueryagain.push(retempHeader);
                                    }
                                    // var tempLen = retempHeader.length;

                                    // for (var x = 0; x <= tempLen - 1; x++) {
                                    //   if (x === 0) {
                                    //     tempHeader.push(retempHeader[x]);
                                    //   } else {
                                    //     // else if(x === tempLen){
                                    //     tempHeader.push(retempHeader[x]);
                                    //     tempHeader.push({ type: "string", role: "annotation" });
                                    //   }
                                    // }

                                    for (let [key, value] of Object.entries(y)) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }

                                    tempColQueryagain.push(tempColQuery.reverse());

                                    var transposed = transpose(tempColQueryagain);

                                    var stringing = [];

                                    transposed.map((arrr, k) => {
                                        var rowarrr = [];
                                        if (k === 0) {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push({
                                                        type: "string",
                                                        role: "annotation",
                                                    });
                                                }
                                            });
                                        } else {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push(inarrr.toString());
                                                }
                                            });
                                        }
                                        stringing.push(rowarrr);
                                    });

                                    // console.log(stringing);
                                    tempColQuery1 = stringing;
                                });
                            } else if (sortType[index] === "Type6") {
                                var retempHeader = [];
                                var tempColQueryagain = [];
                                // console.log(x);
                                x.map((y, j) => {
                                    var tempColQuery = [];
                                    if (j === 0) {
                                        retempHeader = Object.keys(y);
                                        retempHeader = retempHeader.map((head, i) => {
                                            return head.replace(/_/g, " ").toString();
                                        });
                                        tempColQueryagain.push(retempHeader);
                                    }
                                    for (let [key, value] of Object.entries(y)) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }
                                    tempColQueryagain.push(tempColQuery);

                                    // console.log(tempColQueryagain);
                                    var transposed = transpose(tempColQueryagain);
                                    var stringing = [];
                                    transposed.map((arrr, k) => {
                                        var rowarrr = [];
                                        if (k === 0) {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push({
                                                        type: "string",
                                                        role: "annotation",
                                                    });
                                                }
                                            });
                                        } else {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push(inarrr.toString());
                                                }
                                            });
                                        }
                                        stringing.push(rowarrr);
                                    });
                                    // console.log(stringing);
                                    tempColQuery1 = stringing;
                                });
                            } else if (sortType[index] === "Type4") {
                                x.map((y, j) => {
                                    var tempColQuery = [];
                                    var retempHeader = [];
                                    if (j === 0) {
                                        retempHeader = Object.keys(y);
                                        retempHeader = retempHeader.map((head, i) => {
                                            return head.replace(/_/g, " ");
                                        });
                                        var tempLen = retempHeader.length;

                                        for (var x = 0; x <= tempLen - 1; x++) {
                                            if (x === 0) {
                                                tempHeader.push(retempHeader[x]);
                                            } else {
                                                // else if(x === tempLen){
                                                tempHeader.push(retempHeader[x]);
                                                tempHeader.push({
                                                    type: "string",
                                                    role: "annotation",
                                                });
                                            }
                                        }
                                    }

                                    for (let [key, value] of Object.entries(y).reverse()) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }
                                    tempColQuery.reverse();
                                    // console.log(tempColQuery);
                                    var stringing = [];
                                    tempColQuery.map((arrr, k) => {
                                        if (k === 0) {
                                            stringing.push(arrr.toString());
                                        } else {
                                            if (arrr === null) {
                                                arrr = "Null";
                                            }

                                            stringing.push(arrr);
                                            if (arrr === 0) {
                                                arrr = "";
                                            }
                                            stringing.push(arrr.toString());
                                        }
                                    });
                                    // console.log(stringing);
                                    tempColQuery1.push(stringing);
                                });
                            } else if (sortType[index] === "Type2") {
                                var tempHeads = ["surveyType"];
                                var tempColQueryagain = [];
                                var retempHeader = [];

                                x.map((y, j) => {
                                    var tempColQuery = [];

                                    if (j === 0) {
                                        retempHeader = Object.keys(y).reverse();
                                        retempHeader.pop();
                                        // console.log(retempHeader);
                                        retempHeader = retempHeader.map((head, i) => {
                                            return head.replace(/_/g, " ");
                                        });
                                        var tempLen = retempHeader.length;

                                        for (var x = 0; x <= tempLen - 1; x++) {
                                            if (x === 0) {
                                                tempHeader.push(retempHeader[x]);
                                            } else {
                                                // else if(x === tempLen){
                                                tempHeader.push(retempHeader[x]);
                                                tempHeader.push({
                                                    type: "string",
                                                    role: "annotation",
                                                });
                                            }
                                        }
                                    }
                                    for (let [key, value] of Object.entries(y).reverse()) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }
                                    // console.log(tempColQuery);
                                    var tempHead = tempColQuery.pop();
                                    tempHeads.push(tempHead);
                                    tempHeads.push({
                                        type: "string",
                                        role: "annotation",
                                    });
                                    tempColQueryagain.push(tempColQuery);

                                    // console.log(tempHeads);
                                });
                                // console.log(tempColQueryagain);
                                var transposed1 = transpose(tempColQueryagain);
                                // console.log(transposed1);
                                var transposed2 = [];
                                transposed1.map((trans, p) => {
                                    // console.log(trans, retempHeader);
                                    var trans1 = trans;
                                    trans1.unshift(retempHeader[p]);
                                    transposed2.push(trans1);
                                });
                                // console.log(transposed2);
                                var transposed3 = [];
                                transposed2.map((asd, c) => {
                                    var asap = [];
                                    asd.map((asdx, z) => {
                                        if (z === 0) {
                                            asap.push(asdx);
                                        } else {
                                            asap.push(asdx);
                                            if (asd === 0) {
                                                asd = "";
                                            }

                                            asap.push(asdx.toString());
                                        }
                                    });
                                    transposed3.push(asap);
                                });

                                transposed3.unshift(tempHeads);
                                // console.log(tempHeads);
                                tempColQuery1 = transposed3;
                            } else if (sortType[index] === "Type3") {
                                var retempHeader = [];
                                var tempColQueryagain = [];
                                // console.log(x);
                                x.map((y, j) => {
                                    var tempColQuery = [];
                                    retempHeader = Object.keys(y).reverse();
                                    retempHeader = retempHeader.map((head, i) => {
                                        return head.replace(/_/g, " ");
                                    });
                                    // console.log(retempHeader);
                                    for (let [key, value] of Object.entries(y)) {
                                        // console.log(`${key}: ${value}`);
                                        tempColQuery.push(value);
                                    }
                                    tempColQueryagain.push(tempColQuery);

                                    tempColQueryagain.unshift(retempHeader);
                                    // console.log(tempColQueryagain);
                                    var transposed = transpose(tempColQueryagain);
                                    // console.log(transposed);
                                    transposed.unshift(["Types of Actions", "No. of Actions"]);
                                    var stringing = [];

                                    transposed.map((arrr, k) => {
                                        var rowarrr = [];
                                        if (k === 0) {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push({
                                                        type: "string",
                                                        role: "annotation",
                                                    });
                                                }
                                            });
                                        } else {
                                            arrr.map((inarrr, o) => {
                                                if (o === 0) {
                                                    rowarrr.push(inarrr);
                                                } else {
                                                    rowarrr.push(inarrr);
                                                    rowarrr.push(inarrr.toString());
                                                }
                                            });
                                        }
                                        stringing.push(rowarrr);
                                    });

                                    // console.log(stringing);
                                    tempColQuery1 = stringing;
                                });
                            }
                        } else if (chartQueries[index] === "Table") {
                            x.map((y, j) => {
                                var tempColQuery = [];
                                if (j === 0) {
                                    tempHeader = Object.keys(y).reverse();
                                    tempHeader = tempHeader.map((head, i) => {
                                        return head.replace(/_/g, " ");
                                    });
                                } // console.log(tempHeader);
                                for (let [key, value] of Object.entries(y)) {
                                    // console.log(`${key}: ${value}`);
                                    tempColQuery.push(value);
                                }
                                tempColQuery.reverse();
                                // console.log(tempColQuery);
                                var stringing = [];
                                tempColQuery.map((arrr, k) => {
                                    if (k === 0) {
                                        stringing.push(arrr.toString());
                                    } else {
                                        stringing.push(arrr);
                                    }
                                });
                                tempColQuery1.push(stringing);
                            });
                        }else if (chartQueries[index] === "ComboChart"){
                       
                            x.map((y, j) => {
                                var tempColQuery = [];
                                var retempHeader = [];
                                if (j === 0) {
                                    retempHeader = Object.keys(y);
                                    retempHeader = retempHeader.map((head, i) => {
                                        return head.replace(/_/g, " ");
                                    });
                                    var tempLen = retempHeader.length;

                                    for (var x = 0; x <= tempLen - 1; x++) {
                                        if (x === 0) {
                                            tempHeader.push(retempHeader[x]);
                                        } else {
                                            // else if(x === tempLen){
                                            tempHeader.push(retempHeader[x]);
                                            tempHeader.push({
                                                type: "string",
                                                role: "annotation",
                                            });
                                        }
                                    }
                                }

                                for (let [key, value] of Object.entries(y)) {
                                    // console.log(`${key}: ${value}`);
                                    tempColQuery.push(value);
                                }
                                
                                // console.log(tempColQuery);
                                var stringing = [];
                                tempColQuery.map((arrr, k) => {
                                    if (k === 0) {
                                        if (arrr === null) {
                                            arrr = "Null";
                                        }
                                        stringing.push(arrr.toString());
                                    } else {
                                        if (arrr === null) {
                                            arrr = "Null";
                                        }

                                        stringing.push(arrr);
                                        if (arrr === 0) {
                                            arrr = "";
                                        }
                                        stringing.push(arrr.toString());
                                    }
                                });
                                console.log(stringing);
                                tempColQuery1.push(stringing);
                            });
                        }

                        if (tempHeader.length > 0) {
                            tempColQuery1.unshift(tempHeader);
                        }
                        tempColQuery2.push(tempColQuery1);
                        // console.log(tempColQuery2);
                    });
                })
                .then(() => {
                  
                
                    var count = 0;
                    chartTitles.map((arr, i) => {
                        var inArr = [];
                        var inArrTyp = [];
                        var outcount = 0;
                        arr.map((arr1, j) => {
                            inArr.push(tempColQuery2[`${count + outcount}`]);
                            inArrTyp.push(chartQueries[`${count + outcount}`]);
                            count++;
                        });
                        outcount++;

                        FinalVals.push(inArr);
                        FinalTypes.push(inArrTyp);
                    });
                    // console.log(FinalVals);
                   
                    this.setState({
                        FinalVals,
                        FinalTypes,
                        chartTitles,
                        chartSize,
                        haxis,
                        vaxis,
                        colors,
                        cardbreaker,
                        graphbreaker,
                        projectName,
                        finalUnits,
                        reportTitles,
                        finalSort,
                        finalTooltips
                    });
                    console.log(this.state.FinalTypes);

                    this.setState({
                        currentTitle: this.state.chartTitles[parseInt(this.state.tabs)],
                        currentVal: this.state.FinalVals[parseInt(this.state.tabs)],
                        currentType: this.state.FinalTypes[parseInt(this.state.tabs)],
                        currentSize: this.state.chartSize[parseInt(this.state.tabs)],
                        currentHaxis: this.state.haxis[parseInt(this.state.tabs)],
                        currentYaxis: this.state.vaxis[parseInt(this.state.tabs)],
                        currentColor: this.state.colors[parseInt(this.state.tabs)],
                        currentBreaker: [this.state.cardbreaker[parseInt(this.state.tabs)], this.state.graphbreaker[parseInt(this.state.tabs)]],
                        currentUnits: this.state.finalUnits[parseInt(this.state.tabs)],
                        currentSort: this.state.finalSort[parseInt(this.state.tabs)],
                        currentTooltip: this.state.finalTooltips[parseInt(this.state.tabs)],
                        loader: false,
                        loaderText: "Fetching filters...",
                    });
                });

            var chartData = [];
        } else {
            alert("enter a value to filter first");
        }
    };
    handleChange = (e) => {
        // console.log(e.target.value);

        this.setState({
            currentTitle: this.state.chartTitles[parseInt(e.target.value)],
            currentVal: this.state.FinalVals[parseInt(e.target.value)],
            currentType: this.state.FinalTypes[parseInt(e.target.value)],
            currentSize: this.state.chartSize[parseInt(e.target.value)],
            currentHaxis: this.state.haxis[parseInt(e.target.value)],
            currentYaxis: this.state.vaxis[parseInt(e.target.value)],
            currentColor: this.state.colors[parseInt(e.target.value)],
            currentBreaker: [this.state.cardbreaker[parseInt(e.target.value)], this.state.graphbreaker[parseInt(e.target.value)]],
            currentUnits: this.state.finalUnits[parseInt(e.target.value)],
            currentSort: this.state.finalSort[parseInt(e.target.value)],
            currentTooltip: this.state.finalTooltips[parseInt(e.target.value)],
            tabs: e.target.value,
        });
    };

    OutsideClick(e) {
        document.removeEventListener("keydown", this.escFunction, false);
        this.setState({
            popUpStatus: false,
        });
    }
    EscapePop(e) {
        document.removeEventListener("keydown", this.escFunction, false);
        if (e.keyCode === 27) {
            this.setState({
                popUpStatus: false,
            });
        }

        e.preventDefault();
    }
    reportsDownload(report) {
        console.log(report);

        var myData = this.rawresultReports[report];
        console.log(myData);
        // myData.forEach((obj, i) => {
        //   if (i === 0) {
        //     console.log(obj);
        //   }
        // });
        var title = this.state.reportTitles[report];
        console.log(myData);
        try {
            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(myData);
            // console.log(csv);
            const blob = new Blob([csv], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            saveAs(blob, `${title}.csv`);

            // const blob = new Blob([csv], {
            //   type: "application/vnd.ms-excel;charset=utf-8"
            // });
            // saveAs(blob, `${title}.xlsx`);
        } catch (err) {
            console.error(err);
        }
    }
    handleProjChange(e){
        this.setState({
            selectProj: e.target.value,
            currentTitle: [],
            currentVal: [],
            currentType: null,
            currentSize: [],
            currentYaxis: null,
            currentHaxis: null,
            currentColor: null,
            currentBreaker: [],
            currentUnits: [],
            currentSort: [],
            currentTooltip: [],
            tabs: "0",
            FinalVals: [],
            FinalTypes: [],
            chartTitles: [],
            chartSize:[],
            haxis:[],
            vaxis:[],
            colors:null,
            cardbreaker:[],
            graphbreaker:[],
            projectName:[],
            finalUnits:[],
            reportTitles:null,
            finalSort:[],
            finalTooltips:[]
        },()=>{
            this.createDash(this.props.userData, this.state.selectProj);
        })
    }
    render() {
        var renderedHtml = [];
        // var smallHtmlShell = [];
        var mediumHtmlShell = [];
        var bigHtmlShell = [];
        var cardShell = [];
        var tableShell = [];
        // var reportShell = [];

        var optionsBar = {
            chartArea: { top: 50, width: "80%", height: "70%" },
            height: "100%",
            isStacked: false,
            region: "IN",
            legend: {
                position: "top",
                alignment: "end",
                textStyle: {
                    fontName: "'Fira Sans', sans-serif",
                    fontSize: 10,
                    color: "#000",
                    auraColor: "none",
                },
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 8,
                    color: "#000",
                    auraColor: "none",
                },
            },

            vAxes: {
                0: {
                    minorGridlines: {
                        count: 0,
                    },
                    textStyle: {
                        fontName: "'Fira Sans', sans-serif",
                        fontSize: 10,
                        color: "#000",
                        auraColor: "none",
                    },
                    titleTextStyle: {
                        fontName: "'Fira Sans', sans-serif",
                        fontSize: 10,
                        color: "#000",
                        auraColor: "none",
                    },
                },
            },
            hAxes: {
                0: {
                    textPosition: "out",
                    minValue: 0,
                    viewWindowMode: "maximized",

                    textStyle: {
                        fontName: "'Fira Sans', sans-serif",
                        fontSize: 10,
                        color: "#000",
                        auraColor: "none",
                    },
                    titleTextStyle: {
                        fontName: "'Fira Sans', sans-serif",
                        fontSize: 10,
                        color: "#000",
                        auraColor: "none",
                    },
                },
            },
            tooltip: {
                textStyle: {
                    fontName: "'Fira Sans', sans-serif",
                    fontSize: 10,
                    color: "#000",
                    auraColor: "none",
                },
            },
            titleTextStyle: {
                fontName: "'Fira Sans', sans-serif",
                fontSize: 10,
                color: "#000",
                auraColor: "none",
            },
            bar: {
                groupWidth: "40%",
            },
            colors: ["#f37a21", "#1ecc67", "#435eec", "#311b92", "#7a7cff", "#6546c3"],
        };
        var optionsTable = {
            alternatingRowStyle: false,
            showRowNumber: true,
            // height: "100%",
            width: "100%",
            page: 'enable',
            titleTextStyle: {
                fontName: "'Fira Sans', sans-serif",
                fontSize: 10,
                color: "#000",
                auraColor: "none",
            },
            tooltip: {
                textStyle: {
                    fontName: "'Fira Sans', sans-serif",
                    fontSize: 10,
                    color: "#000",
                    auraColor: "none",
                },
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 8,
                    color: "#000",
                    auraColor: "none",
                },
            },
            legend: {
                position: "top",
                alignment: "end",
                textStyle: {
                    fontName: "'Fira Sans', sans-serif",
                    fontSize: 10,
                    color: "#000",
                    auraColor: "none",
                },
            },
        };
        var optionsPie = {
            chartArea: { top: 50, width: "80%", height: "70%" },
            height: "100%",
            pieHole: "",
            region: "IN",
            legend: {
                textStyle: {
                    fontName: "'Fira Sans', sans-serif",
                    fontSize: 10,
                    color: "#000",
                    auraColor: "none",
                },
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 8,
                    color: "#000",
                    auraColor: "none",
                },
            },
            titleTextStyle: {
                fontName: "'Fira Sans', sans-serif",
                fontSize: 10,
                color: "#000",
                auraColor: "none",
            },
            colors: ["#1ecc67", "#e73f0a", "#311b92", "#59d9d8", "#7a7cff", "#435eec", "#6546c3"],
        };

        if (this.state.currentType) {
            console.log(this.state.currentType) 
            this.state.currentType.map((type, index) => {
                if (type === "Card") {
                    let card = (
                        <div key={index} className="card">
                            <div className="cardTitle">
                                <div className="cardTitleText">{this.state.currentTitle[index]}</div>
                            <div className="cardVal">
                                <div className="cardValue">{this.state.currentVal[index][0]}</div> {this.state.currentTooltip[index]  ?
                                  <div className='infoShell'> <Tippy
                                 content={this.state.currentTooltip[index]}
                               className= 'tooltip'
                                     animation="scale-subtle"
                                     theme="material"
                                     arrow={true}
                                     duration={200}
                                     delay={[75, 0]}
                                     distance={8}
                                   >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> 
                               </Tippy>
                             
                                </div> 
                                :
                                null
                                }  
                            </div>
                        </div></div>
                    );
                    cardShell.push(card);
                } else if (type === "Table" && this.state.currentVal[index].length > 0) {
                    // console.log(this.state.currentVal[index]);
                    let tabled = (
                        <div className="tableChart" key={`${index}+${type}+${this.state.currentVal[index][0]}`} onClick={() => this.onChartClick(index)}>
                            <div className="chartTitle">
                                <div className="chartTitleText">{this.state.currentTitle[index]}</div> {this.state.currentTooltip[index]  ?
                                  <div className='infoShell'> <Tippy
                                 content={this.state.currentTooltip[index]}
                               className= 'tooltip'
                                     animation="scale-subtle"
                                     theme="material"
                                     arrow={true}
                                     duration={200}
                                     delay={[75, 0]}
                                     distance={8}
                                   >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> 
                               </Tippy>
                             
                                </div> 
                                :
                                null
                                }  
                            </div>
                            <div className=" " style={{ color: "black" }}>
                                <Chart chartType="Table" data={this.state.currentVal[index]} options={optionsTable} mapsApiKey="AIzaSyD_srJ5CWY6XfjOVb9zGWdwXQGUduGe8Yw" />
                            </div>
                        </div>
                    );
                    tableShell.push(tabled);
                } else if ((type === "ColumnChart" || "PieChart" || "ComboChart") && this.state.currentVal[index].length > 0) {
                    var labelledData = this.state.currentVal[index];
                    // var barlength = labelledData.length - 1;

                    var finalOpt = Object.assign({}, optionsBar, {
                        hAxis: {
                            title: this.state.currentHaxis[index],
                        },
                        vAxis: {
                            title: this.state.currentYaxis[index],
                        },
                        vAxes: {
                            0: {
                                minValue: 0,
                                format: this.state.currentUnits[index],
                                minorGridlines: {
                                    count: 0,
                                },
                                textStyle: {
                                    fontName: "'Fira Sans', sans-serif",
                                    fontSize: 10,
                                    color: "#000",
                                    auraColor: "none",
                                },
                                titleTextStyle: {
                                    fontName: "'Fira Sans', sans-serif",
                                    fontSize: 10,
                                    color: "#000",
                                    auraColor: "none",
                                },
                            },
                        },
                        colors: this.state.currentColor[index],
                    });
                    if (type === "PieChart") {
                        finalOpt = Object.assign({}, optionsPie, {
                            hAxis: {
                                title: this.state.currentHaxis[index],
                            },
                            vAxis: {
                                title: this.state.currentYaxis[index],
                            },
                            colors: this.state.currentColor[index],
                        });
                    }
                    if(type === "ComboChart"){
                        
                        var target = parseInt(this.state.currentSort[index])
                        console.log(target, index)
                        // var asdf = { series: {[target]: {type : 'line'}}}
                        var finalOpt = Object.assign({}, optionsBar, {
                            hAxis: {
                                title: this.state.currentHaxis[index],
                            },
                            vAxis: {
                                title: this.state.currentYaxis[index],
                            },
                            vAxes: {
                                0: {
                                    minValue: 0,
                                    format: this.state.currentUnits[index],
                                    minorGridlines: {
                                        count: 0,
                                    },
                                    textStyle: {
                                        fontName: "'Fira Sans', sans-serif",
                                        fontSize: 10,
                                        color: "#000",
                                        auraColor: "none",
                                    },
                                    titleTextStyle: {
                                        fontName: "'Fira Sans', sans-serif",
                                        fontSize: 10,
                                        color: "#000",
                                        auraColor: "none",
                                    },
                                },
                            },
                            seriesType: 'bars',
                            series: {[target]: {type : 'line'}},
                        
                            colors: this.state.currentColor[index],
                        }); 
                        // finalOpt['series'][target] = { type: 'line' }
                        console.log(finalOpt)
                    }
                
                    // console.log(finalOpt);
                    if (this.state.currentSize[index] === "med") {
                        var mediumHtml = (
                            <div className="big" key={`${index}+${type}+${this.state.currentVal[index][0]}`} onClick={() => this.onChartClick(index)}>
                                <div className="chartTitle">
                                    <div className="chartTitleText">{this.state.currentTitle[index]}</div> {this.state.currentTooltip[index]  ?
                                  <div className='infoShell'> <Tippy
                                 content={this.state.currentTooltip[index]}
                               className= 'tooltip'
                                     animation="scale-subtle"
                                     theme="material"
                                     arrow={true}
                                     duration={200}
                                     delay={[75, 0]}
                                     distance={8}
                                   >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> 
                               </Tippy>
                             
                                </div> 
                                :
                                null
                                }  
                                </div>
                                <div className="bigChartShell">
                                    <Chart chartType={type} data={labelledData} options={finalOpt} mapsApiKey="AIzaSyD_srJ5CWY6XfjOVb9zGWdwXQGUduGe8Yw" />
                                </div>
                            </div>
                        );
                        mediumHtmlShell.push(mediumHtml);
                    } else {
                        var bigHtml = (
                            <div className="big full" key={`${index}+${type}+${this.state.currentVal[index][0]}`} onClick={() => this.onChartClick(index)}>
                                <div className="chartTitle">
                                    <div className="chartTitleText">{this.state.currentTitle[index]}</div>   
                                 {this.state.currentTooltip[index]  ?
                                  <div className='infoShell'> <Tippy
                                 content={this.state.currentTooltip[index]}
                               className= 'tooltip'
                                     animation="scale-subtle"
                                     theme="material"
                                     arrow={true}
                                     duration={200}
                                     delay={[75, 0]}
                                     distance={8}
                                   >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> 
                               </Tippy>
                             
                                </div> 
                                :
                                null
                                }  
                                </div>
                                <div className="bigChartShell">
                                    <Chart chartType={type} data={labelledData} options={finalOpt} mapsApiKey="AIzaSyD_srJ5CWY6XfjOVb9zGWdwXQGUduGe8Yw" />
                                </div>
                            </div>
                        );
                        bigHtmlShell.push(bigHtml);
                    }
                } else {
                    var mediumHtml = (
                        <div className="medium" key={index}>
                            <div className="chartTitle">
                                <div className="chartTitleText">{this.state.currentTitle[index]}</div>
                                {this.state.currentTooltip[index]  ?
                                  <div className='infoShell'> <Tippy
                                 content={this.state.currentTooltip[index]}
                               className= 'tooltip'
                                     animation="scale-subtle"
                                     theme="material"
                                     arrow={true}
                                     duration={200}
                                     delay={[75, 0]}
                                     distance={8}
                                   >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg> 
                               </Tippy>
                             
                                </div> 
                                :
                                null
                                }    </div>
                            <div
                                className="mediumChartShell"
                                style={{
                                    color: "black",
                                }}
                            >
                                Data not available as per our filters
                            </div>
                        </div>
                    );
                    mediumHtmlShell.push(mediumHtml);
                }
            });
        }

        renderedHtml = this.state.currentType ? (
            <div className="chartAnime" key={this.state.tabs}>
                {" "}
                {cardShell.length > 0 ? (
                    <div>
                        <div className="cardHeader">
                            <div className="cardHeaderText">{this.state.currentBreaker[0].toUpperCase()}</div>
                        </div>
                        <div className="cardShell">
                            <div className="cards">{cardShell}</div>
                        </div>
                    </div>
                ) : null}{" "}
                {tableShell.length > 0 || mediumHtmlShell.length > 0 || bigHtmlShell.length > 0 ? (
                    <div>
                        <div className="separator" />
                        <div className="bigHeader">
                            <div className="bigHeaderText">{this.state.currentBreaker[1].toUpperCase()}</div>
                        </div>
                        <div className="graphs">
                            {tableShell ? (
                                <div>
                                    <div className="bigRow">
                                        <div className="bigShell">{tableShell}</div>
                                    </div>
                                    <div className="separator1" />
                                </div>
                            ) : null}{" "}
                            {mediumHtmlShell ? (
                                <div>
                                    <div className="bigRow">
                                        <div className="bigShell">{mediumHtmlShell}</div>
                                    </div>
                                    <div className="separator1" />
                                </div>
                            ) : null}{" "}
                            {bigHtmlShell ? (
                                <div>
                                    <div className="bigRow">
                                        <div className="bigShell">{bigHtmlShell}</div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        ) : this.state.reportTitles ? (
            <div>
                <div className="reportsHeader">
                    <div className="reportsHeaderText">Reports</div>
                </div>

                <div className="reportShell">
                    {this.state.reportTitles.map((title, i) => {
                        return (
                            <div className="reportsTitle" key={`${title}${i}`}>
                                <div className="reportsTitleText">{title}</div>
                                <div className="reportsDownload">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="35" viewBox="0 0 24 24" onClick={() => this.reportsDownload(i)}>
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                                    </svg>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : null;
        return (
            <div>
                <div className="dashboard">
                    <div className="appBar" style={{ backgroundColor: this.state.projectInfo[6] }}>
                        <div className="appBar-left">
                            <h1> {this.state.projectInfo[0]}</h1>
                        </div>

                        <div className="appBar-right">
                            <div className="navbar">
                                <form >
                                    <select onChange={this.handleProjChange} value={this.state.selectProj} name="Home" className="projectSelect" style={{ backgroundColor: this.state.projectInfo[6] }}>
                                        {this.state.projectList.map((project, index) => {
                                            return (
                                                <option key={index} value={project}>
                                                    {project}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </form>
                            </div>{" "}
                            <div className="moreMenu">
                                <div className="threeDot" onClick={this.handleMoreClick} ref={this.targetRef} />
                                {this.state.showDotMenu === true ? (
                                    <Grommet theme={this.customTheme}>
                                        <Drop
                                            align={{ top: "bottom", right: "left" }}
                                            target={this.targetRef.current}
                                            onClickOutside={() =>
                                                this.setState({
                                                    showDotMenu: false,
                                                })
                                            }
                                            onEsc={() =>
                                                this.setState({
                                                    showDotMenu: false,
                                                })
                                            }
                                        >
                                            <div id="dropdown" tabIndex="0" onBlur={this.handleMoreClick}>
                                                <div className="logout" onClick={this.logout}>
                                                    Logout
                                                </div>
                                                <div className="goToWebsite" onClick={this.goToWebsite}>
                                                    <a href={"https://www.withinreach.in/"} target={"_blank"}>
                                                        Go To Website
                                                    </a>
                                                </div>
                                            </div>
                                        </Drop>
                                    </Grommet>
                                ) : null}
                            </div>
                        </div>
                    </div>{" "}
                    <div className="mainShell">
                        <div className={`main ${this.state.preload}`}>
                            {" "}
                            <div className="info">
                                <div className="info1">
                                    <div className="wrapper">
                                        <div
                                            className="logo"
                                            style={{
                                                backgroundImage: `url(${this.state.projectInfo[5]})`,
                                            }}
                                        />
                                        {this.state.projectInfo[8] ? (
                                            <div className="title">
                                                <div className="nestedTitle">{this.state.projectInfo[8]}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="leftLine" />
                                <div className="info2">
                                    <div className="wrapper">
                                        <div className="top2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                                            </svg>
                                            <p>{`Description of ${this.state.projectInfo[0]}`}</p>
                                        </div>
                                        <div className="bottom2">
                                            <p>{this.state.projectInfo[1]}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightLine" />
                                <div className="info3">
                                    <div className="wrapper">
                                        <div className="top3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                                                <path fill="none" d="M0 0h24v24H0z" />
                                            </svg>
                                            <div className="date">
                                                Start:&nbsp;
                                                <strong>{this.state.projectInfo[2]}</strong>
                                                &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;End:&nbsp; <strong>{this.state.projectInfo[3]}</strong>
                                            </div>
                                        </div>
                                        <div className="bottom3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                                <path d="M0 0h24v24H0z" fill="none" />
                                            </svg>
                                            <p className="Partners">{this.state.projectInfo[4]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="drops">
                                <div className="filSearch">Filter Search</div>
                                <Grommet theme={this.customTheme}>
                                    {this.state.filterResults.map((val, i) => {
                                        return (
                                            <div className="s1" key={i}>
                                                <div className="texty">{this.filterTxt[i]}</div>{" "}
                                                <Select
                                                    id="select"
                                                    required={false}
                                                    name={this.filterTxt[i]}
                                                    placeholder="Select"
                                                    value={this.state.filterLen[i]}
                                                    options={val}
                                                    onChange={({ option }) => {
                                                        this.queryOnChange(option, i);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="clearBut">
                                        {" "}
                                        <this.MyStyledButton
                                            type="submit"
                                            hoverIndicator={"false"}
                                            onClick={this.clearQuery}
                                            className="Submit"
                                            primary
                                            label="Clear"
                                            style={{ backgroundColor: this.state.projectInfo[7], border: `2px solid ${this.state.projectInfo[7]}` }}
                                        />
                                    </div>

                                    <div className="subBut">
                                        {" "}
                                        <this.MyStyledButton
                                            type="submit"
                                            hoverIndicator
                                            onClick={this.submitQuery}
                                            className="Submit"
                                            primary
                                            label="Submit"
                                            style={{ backgroundColor: this.state.projectInfo[7], border: `2px solid ${this.state.projectInfo[7]}` }}
                                        />
                                    </div>
                                </Grommet>
                            </div>
                            <div className='dashSelectWrapper'>
                            <div className="dashSelect">
                                <form >
                                    <select onChange={this.handleChange} name="Home" className="dashSelectInner" style={{ color: this.state.projectInfo[6] }}>
                                        {this.state.projectName.map((project, index) => {
                                            return (
                                                <option key={index} value={index}>
                                                    {project}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </form>
                            </div></div>
                            {renderedHtml}
                        </div>
                    </div>{" "}
                    <div className={`loaderScreen ${this.state.loader}`}>
                        <p className="loadingtext">{this.state.loaderText}</p>
                        <div className={`lds-ripple ${this.state.loader}`}>
                            <div />
                            <div />
                        </div>
                    </div>
                    <div className={`overLay1 ${this.state.popUpStatus}`} onClick={this.OutsideClick} />
                    {this.state.popUp === null
                        ? null
                        : [
                              this.state.popUp[1] !== "Table" ? (
                                  <div key={`popUp ${this.state.popUp[2]}`} className={`popUp ${this.state.popUpStatus}`}>
                                      <div className="chartTitle">
                                          <div className="chartTitleText">{this.state.popUp[0]}</div>
                                          <div className="floating">
                                              <div className="print">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="100" viewBox="0 0 28 23" onClick={this.download}>
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                                                  </svg>
                                              </div>
                                              <div className="print">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 28 22" onClick={this.print}>
                                                      <circle cx="12" cy="12" r="3.2" />
                                                      <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                  </svg>
                                              </div>
                                              <div className="close">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 28 24" onClick={this.closePopup}>
                                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                  </svg>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="popChart">
                                          <Chart
                                              chartType={this.state.popUp[1]}
                                              loader={<div>Loading Chart</div>}
                                              data={this.state.popUp[2]}
                                              options={{
                                                  chartArea: {
                                                      top: 50,
                                                      width: "80%",
                                                      height: "65%",
                                                  },
                                                  height: "100%",
                                                  isStacked: false,
                                                  region: "IN",
                                                  legend: {
                                                      position: "top",
                                                      alignment: "end",
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },

                                                  annotations: {
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 8,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                      alwaysOutside: true,
                                                  },
                                                  hAxis: {
                                                      title: this.state.popUp[3],
                                                      textPosition: "out",
                                                      viewWindowMode: "maximized",
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                      titleTextStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },
                                                  titleTextStyle: {
                                                      fontName: "'Fira Sans', sans-serif",
                                                      fontSize: 10,
                                                      color: "#000",
                                                      auraColor: "none",
                                                  },
                                                  vAxis: {
                                                      minValue: 0,

                                                      format: "#'%'",

                                                      title: this.state.popUp[4],

                                                      format: "decimal",
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                      titleTextStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },
                                                  bar: {
                                                      groupWidth: "40%",
                                                  },
                                                  tooltip: {
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },

                                                  colors: this.state.popUp[5],
                                              }}
                                              mapsApiKey="AIzaSyD_srJ5CWY6XfjOVb9zGWdwXQGUduGe8Yw"
                                              getChartWrapper={(chartWrapper) => {
                                                  this.setState({
                                                      chartWrapper,
                                                  });
                                              }}
                                          />{" "}
                                      </div>
                                  </div>
                              ) : (
                                  <div className={`popUp ${this.state.popUpStatus}`} key={`popUp1 ${this.state.popUp[2]}`}>
                                      <div className="chartTitle">
                                          <div className="chartTitleText">{this.state.popUp[0]}</div>
                                          <div className="floating">
                                              <div className="print">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="100" viewBox="0 0 28 23" onClick={this.download}>
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                                                  </svg>
                                              </div>
                                              <div className="close">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 28 24" onClick={this.closePopup}>
                                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                  </svg>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="popChart">
                                          <Chart
                                              chartType={this.state.popUp[1]}
                                              loader={<div>Loading Chart</div>}
                                              data={this.state.popUp[2]}
                                              options={{
                                                  alternatingRowStyle: false,
                                                  showRowNumber: true,
                                                  height: "100%",
                                                  width: "100%",
                                                  titleTextStyle: {
                                                      fontName: "'Fira Sans', sans-serif",
                                                      fontSize: 10,
                                                      color: "#000",
                                                      auraColor: "none",
                                                  },
                                                  tooltip: {
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },
                                                  annotations: {
                                                      alwaysOutside: true,
                                                      textStyle: {
                                                          fontSize: 8,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },
                                                  legend: {
                                                      position: "top",
                                                      alignment: "end",
                                                      textStyle: {
                                                          fontName: "'Fira Sans', sans-serif",
                                                          fontSize: 10,
                                                          color: "#000",
                                                          auraColor: "none",
                                                      },
                                                  },
                                              }}
                                              mapsApiKey="AIzaSyD_srJ5CWY6XfjOVb9zGWdwXQGUduGe8Yw"
                                              getChartWrapper={(chartWrapper) => {
                                                  this.setState({
                                                      chartWrapper,
                                                  });
                                              }}
                                          />
                                      </div>
                                  </div>
                              ),
                          ]}
                    <div className="footer">
                        <div className="leftFooter">© Copyright 2021 Impact Data Services Pvt. Ltd. All Rights Reserved. </div>
                        <div className="rightFooter">
                            <a href={"mailto: whats@withinreach.in"} target={"_blank"}>
                                Help & Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
